import { Component, OnInit, Input } from '@angular/core';
declare var require: any;

// var $  = require( 'jquery' );
// var dt = require( 'datatables.net' )( window, $ );

// import $ from 'jquery';
// import dt from 'datatables.net';

// require( 'jquery' );
// require( 'jszip' );
// require( 'pdfmake' );
// require( 'datatables.net-dt' )();
// // require( 'datatables.net-editor-dt' )();
// require( 'datatables.net-autofill-dt' )();
// require( 'datatables.net-buttons-dt' )();
// require( 'datatables.net-buttons/js/buttons.colVis.js' )();
// require( 'datatables.net-buttons/js/buttons.flash.js' )();
// require( 'datatables.net-buttons/js/buttons.html5.js' )();
// require( 'datatables.net-buttons/js/buttons.print.js' )();
// require( 'datatables.net-colreorder-dt' )();
// require( 'datatables.net-fixedcolumns-dt' )();
// require( 'datatables.net-fixedheader-dt' )();
// require( 'datatables.net-keytable-dt' )();
// require( 'datatables.net-responsive-dt' )();
// require( 'datatables.net-rowgroup-dt' )();
// require( 'datatables.net-rowreorder-dt' )();
// require( 'datatables.net-scroller-dt' )();
// require( 'datatables.net-searchpanes-dt' )();
// require( 'datatables.net-select-dt' )();



@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})



export class DataTableComponent implements OnInit {

  @Input() data = [];
  constructor() { }

  ngOnInit() {
    // dt.DataTable();
  }

}
