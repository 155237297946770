import { Injectable } from '@angular/core';
// import { Http, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Ortak } from './ortak';
// import "reflect-metadata";

/*
  Generated class for the Servis provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
@Injectable({ providedIn: 'root' })
export class ServisLogo {


  clientID = 1;
  clientUserName = "volvox";
  clientPass = "123";
  servisAdresiOn = "http://localhost:54315/"
  constructor(public ortak: Ortak, private http: HttpClient) {

    //http://mimax.dyndns.org:3232/WebBinding/W_SERVIS.aspx?t=Cariler&key=1rQUUEsyc9&client_id=10;
    //http://185.81.237.68/WebBinding/W_SERVIS.aspx?t=Cariler&key=1rQUUEsyc9&client_id=10
  }

  makePostRequest(servisAdresi: string, postData: any) {
    return new Promise((resolve, reject) => {
      this.ortak.sifrele(this.ortak.createTs()).then(s => {
        var firmaDonemNo = "";
        var servisAdresiHam = servisAdresi;
        servisAdresi = this.servisAdresiOn + "api" + servisAdresi;
   
        servisAdresi = servisAdresi + "?firma_donem=" + firmaDonemNo + "&client_id=" + this.clientID + "&z=" + s;
        const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("token") });
        // myHeaders.append('Content-type', 'multipart/form-data');

        this.http.post(servisAdresi, postData, { headers: headers })
          .subscribe(data => {
            // const anyObj = data as any;
            // resolve(anyObj);
            resolve(data);
          }, (error) => {

            if (error.status == 401) {
              console.log("token alınacak");
              var formData = "grant_type=password&username=" + this.clientUserName + "&password=" + this.clientPass;
              this.http.post(this.servisAdresiOn + "token?z=" + s, formData).subscribe(a => {
                var sonuc = <any>a;
                localStorage.setItem("token", sonuc.access_token);
                this.makePostRequest(servisAdresiHam, postData).then(c => {
                  resolve(c);
                }).catch(xyz => {
                  reject(xyz);
                })
              }, (error) => {
                reject(error);
              })
            }
            else {
              reject(error);
            }

          });
      });
    });


  };

  makePostRequestWithoutJsonResult(servisAdresi: string, postData: any) {
    return new Promise((resolve, reject) => {
      this.ortak.sifrele(this.ortak.createTs()).then(s => {
        var firmaDonemNo = "";
        var servisAdresiHam = servisAdresi;
        servisAdresi = this.servisAdresiOn + "api" + servisAdresi;
 
        servisAdresi = servisAdresi + "?firma_donem=" + firmaDonemNo + "&client_id=" + this.clientID + "&z=" + s;

        const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("token") });

        this.http.post(servisAdresi, postData, { headers: headers })
          .subscribe(data => {
            resolve(data);
          }, (error) => {

            if (error.status == 401) {
              console.log("token alınacak");
              var formData = "grant_type=password&username=" + this.clientUserName + "&password=" + this.clientPass;
              this.http.post(this.servisAdresiOn + "token?z=" + s, formData).subscribe(a => {
                var sonuc = <any>a;
                localStorage.setItem("token", sonuc.access_token);
                this.makePostRequestWithoutJsonResult(servisAdresiHam, postData).then(c => {
                  resolve(c);
                }).catch(xyz => {
                  reject(xyz);
                })
              }, (error) => {
                reject(error);
              })
            }
            else {
              reject(error);
            }
          });
      });

    });
  };

  makeGetRequest(servisAdresi: string) {
    return new Promise((resolve, reject) => {
      this.ortak.sifrele(this.ortak.createTs()).then(s => {

        var firmaDonemNo = "";
        var servisAdresiHam = servisAdresi;

        servisAdresi = this.servisAdresiOn + "api" + servisAdresi;
      
        servisAdresi = servisAdresi + "?firma_donem=" + firmaDonemNo + "&client_id=" + this.clientID + "&z=" + s;
        const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("token") });


        this.http.get(servisAdresi, { headers: headers })
          .subscribe(data => {
            resolve(data);
          }, (error) => {
            if (error.status == 401) {
              var formData = "grant_type=password&username=" + this.clientUserName + "&password=" + this.clientPass;
              this.http.post(this.servisAdresiOn + "token?z=" + s, formData).subscribe(a => {
                var sonuc = <any>a;
                localStorage.setItem("token", sonuc.access_token);
                this.makeGetRequest(servisAdresiHam).then(c => {
                  resolve(c);
                }).catch(xyz => {
                  reject(xyz);
                })
              }, (error) => {
                reject(error);
              })
            }
            else {
              reject(error);
            }
          });
      });
    });
  }

  makeGetRequestWithoutJsonResult(servisAdresi: string) {
    return new Promise((resolve, reject) => {

      this.ortak.sifrele(this.ortak.createTs()).then(s => {
        var firmaDonemNo = "";
        var servisAdresiHam = servisAdresi;
        servisAdresi = this.servisAdresiOn + "api" + servisAdresi;
       
        servisAdresi = servisAdresi + "?firma_donem=" + firmaDonemNo + "&client_id=" + this.clientID + "&z=" + s;
        const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("token") });
        this.http.get(servisAdresi, { headers: headers })
          .subscribe(data => {
            resolve(data);
          }, (error) => {
            if (error.status == 401) {
              console.log("token alınacak");
              var formData = "grant_type=password&username=" + this.clientUserName + "&password=" + this.clientPass;
              this.http.post(this.servisAdresiOn + "token?z=" + s, formData).subscribe(a => {
                var sonuc = <any>a;
                localStorage.setItem("token", sonuc.access_token);
                this.makeGetRequestWithoutJsonResult(servisAdresiHam).then(c => {
                  resolve(c);
                }).catch(xyz => {
                  reject(xyz);
                })
              }, (error) => {
                reject(error);
              })
            }
            else {
              reject(error);
            }
          });

      });
    });
  }

}
