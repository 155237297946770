import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SideNavOuterToolbarModule, SideNavInnerToolbarModule, SingleCardModule } from './layouts';
import { FooterModule, LoginFormModule } from './shared/components';
import { AuthService, ScreenService, AppInfoService } from './shared/services';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule } from '@angular/forms';
import { KategoriListesiModule } from './pages/katalog/kategori/kategori-listesi/kategori-listesi.module';
import { KategoriListesiComponent } from './pages/katalog/kategori/kategori-listesi/kategori-listesi.component';
import { CommonModule } from '@angular/common';
import { DxTreeListModule, DxCheckBoxModule, DxButtonModule, DxChartModule } from 'devextreme-angular';
import { HeaderInterceptor } from './shared/interceptors/headers.interceptor';
import { DataTableComponent } from './shared/components/data-table/data-table.component';
import { MesajSablonListeComponent } from './pages/icerik/sablon/mesaj-sablon-liste/mesaj-sablon-liste.component';
import { MesajSablonEkleGuncelleComponent } from './pages/icerik/sablon/mesaj-sablon-ekle-guncelle/mesaj-sablon-ekle-guncelle.component';
import { PazarYeriComponent } from './pages/entegrasyon/pazar-yeri/pazar-yeri.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    AppComponent,
    DataTableComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    LoginFormModule,
    AppRoutingModule,
    NgxSpinnerModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true }, AuthService, ScreenService, AppInfoService],
  bootstrap: [AppComponent],
  exports: [],
  entryComponents: []
})
export class AppModule { }
