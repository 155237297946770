import { MesajSablonListeModule } from './pages/icerik/sablon/mesaj-sablon-liste/mesaj-sablon-liste.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginFormComponent } from './shared/components';
import { AuthGuardService } from './shared/services';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DisplayDataComponent } from './pages/display-data/display-data.component';
import { DxChartModule, DxDataGridModule, DxFormModule, DxPieChartModule, DxResponsiveBoxModule } from 'devextreme-angular';
import { ExcelSenkAnasayfaComponent } from './pages/entegrasyon/excel/excel-senk-anasayfa/excel-senk-anasayfa.component';
import { CommonModule } from '@angular/common';

const routes: Routes = [
  {
    path: 'display-data',
    component: DisplayDataComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'login-form',
    component: LoginFormComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'urun-listesi',
    loadChildren: () =>
      import('./pages/katalog/urun/urun-listesi/urun-listesi.module').then(
        m => m.UrunListesiModule
      )
  },
  {
    path: 'urun-yonetimi',
    loadChildren: () =>
      import('./pages/katalog/urun/urun-yonetimi/urun-yonetimi.module').then(
        m => m.UrunYonetimiModule
      )
  },
  {
    path: 'kategori-ozellikleri',
    loadChildren: () =>
      import('./pages/katalog/kategori-ozellikleri/kategori-ozellikleri.module').then(
        m => m.KategoriOzellikleriModule
      )
  },
  {
    path: 'kategori-listesi',
    loadChildren: () =>
      import('./pages/katalog/kategori/kategori-listesi/kategori-listesi.module').then(
        m => m.KategoriListesiModule
      )
  },
  {
    path: 'kategori-yonetimi',
    loadChildren: () =>
      import('./pages/katalog/kategori/kategori-yonetimi/kategori-yonetimi.module').then(
        m => m.KategoriYonetimiModule
      )
  },
  {
    path: 'medya-listesi',
    loadChildren: () =>
      import('./pages/katalog/medya/medya-listesi/medya-listesi.module').then(
        m => m.MedyaListesiModule
      )
  },
  {
    path: 'medya-yonetimi',
    loadChildren: () =>
      import('./pages/katalog/medya/medya-yonetimi/medya-yonetimi.module').then(
        m => m.MedyaYonetimiModule
      )
  },
  {
    path: 'birim-listesi',
    loadChildren: () =>
      import('./pages/katalog/birim/birim-listesi/birim-listesi.module').then(
        m => m.BirimListesiModule
      )
  },
  {
    path: 'marka-listesi',
    loadChildren: () =>
      import('./pages/katalog/marka/marka-listesi/marka-listesi.module').then(
        m => m.MarkaListesiModule
      )
  },
  {
    path: 'marka-yonetimi',
    loadChildren: () =>
      import('./pages/katalog/marka/marka-yonetimi/marka-yonetimi.module').then(
        m => m.MarkaYonetimiModule
      )
  },
  {
    path: 'sayfa-listesi/:topicSayfasi',
    loadChildren: () =>
      import('./pages/icerik/sayfa/sayfa-listesi/sayfa-listesi.module').then(
        m => m.SayfaListesiModule
      )
  },
  {
    path: 'sayfa-yonetimi/:topicSayfasi/:sayfaID',
    loadChildren: () =>
      import('./pages/icerik/sayfa/sayfa-yonetimi/sayfa-yonetimi.module').then(
        m => m.SayfaYonetimiModule
      )
  },
  {
    path: 'musteri-listesi',
    loadChildren: () =>
      import('./pages/musteri/musteriler/musteri-listesi/musteri-listesi.module').then(
        m => m.MusteriListesiModule
      )
  },
  {
    path: 'musteri-yonetimi',
    loadChildren: () =>
      import('./pages/musteri/musteriler/musteri-yonetimi/musteri-yonetimi.module').then(
        m => m.MusteriYonetimiModule
      )
  },
  {
    path: 'logo-senk-anasayfa',
    loadChildren: () =>
      import('./pages/entegrasyon/logo/logo-senk-anasayfa/logo-senk-anasayfa.module').then(
        m => m.LogoSenkAnasayfaModule
      )
  },
  {
    path: 'siparisler/:tip',
    loadChildren: () =>
      import('./pages/satislar/siparisler/siparisler.module').then(
        m => m.SiparislerModule
      )
  },
  {
    path: 'ayarlar/:tip',
    loadChildren: () =>
      import('./pages/ayarlar/ayarlar/ayarlar.module').then(
        m => m.AyarlarModule
      )
  },
  {
    path: 'giris',
    loadChildren: () =>
      import('./pages/giris/giris.module').then(
        m => m.GirisModule
      )
  },
  {
    path: 'odeme-metodlari',
    loadChildren: () =>
      import('./pages/firma/odeme-metodlari/odeme-metodlari.module').then(
        m => m.OdemeMetodlariModule
      )
  },
  {
    path: 'kargo-firma-liste',
    loadChildren: () =>
      import('./pages/tanimlama/kargo-firma/kargo-firma-liste/kargo-firma-liste.module').then(
        m => m.KargoFirmaListeModule
      )
  },
  {
    path: 'kargo-firma-ozellik/:firmaID',
    loadChildren: () =>
      import('./pages/tanimlama/kargo-firma/kargo-firma-ozellik/kargo-firma-ozellik.module').then(
        m => m.KargoFirmaOzellikModule
      )
  },
  {
    path: 'banka-kartlari/:sanalPosOdemeSistemID',
    loadChildren: () =>
      import('./pages/firma/odeme-metodlari/firma-sanal-pos-odeme-sistemi-banka-kartlari/firma-sanal-pos-odeme-sistemi-banka-kartlari.module').then(
        m => m.FirmaSanalPosOdemeSistemiBankaKartlariModule
      )
  },
  {
    path: 'para-birimleri',
    loadChildren: () =>
      import('./pages/tanimlama/para-birimleri//para-birimleri.module').then(
        m => m.ParaBirimleriModule
      )
  },
  {
    path: 'excel-senk-anasayfa',
    loadChildren: () =>
      import('./pages/entegrasyon/excel/excel-senk-anasayfa/excel-senk-anasayfa.module').then(
        m => m.ExcelSenkAnasayfaModule
      )
  },
  {
    path: 'kampanya-listesi',
    loadChildren: () =>
      import('./pages/promosyon/kampanya/kampanya-listesi/kampanya-listesi.module').then(
        m => m.KampanyaListesiModule
      )
  },
  {
    path: 'kampanya-ekle-guncelle/:kampanyaID',
    loadChildren: () =>
      import('./pages/promosyon/kampanya/kampanya-ekle-guncelle/kampanya-ekle-guncelle.module').then(
        m => m.KampanyaEkleGuncelleModule
      )
  },
  {
    path: 'kampanya-detay-listesi/:kampanyaID/:kampanyaAdi',
    loadChildren: () =>
      import('./pages/promosyon/kampanya/kampanya-detay-listesi/kampanya-detay-listesi.module').then(
        m => m.KampanyaDetayListesiModule
      )
  },
  {
    path: 'kampanya-detay-ekle-guncelle/:kampanyaID/:kampanyaDetayID',
    loadChildren: () =>
      import('./pages/promosyon/kampanya/kampanya-detay-ekle-guncelle/kampanya-detay-ekle-guncelle.module').then(
        m => m.KampanyaDetayEkleGuncelleModule
      )
  },
  {
    path: 'yetki-yonetimi',
    loadChildren: () =>
      import('./pages/musteri/musteriler/yetki-yonetimi/yetki-yonetimi.module').then(
        m => m.YetkiYonetimiModule
      )
  },
  {
    path: 'tahsilat-listesi',
    loadChildren: () =>
      import('./pages/tahsilatlar/tahsilat-listesi/tahsilat-listesi.module').then(
        m => m.TahsilatListesiModule
      )
  },
  {
    path: 'tahsilat-ekle/:cid/:erpId',
    loadChildren: () =>
      import('./pages/tahsilatlar/tahsilat-ekle/tahsilat-ekle.module').then(
        m => m.TahsilatEkleModule
      )
  },
  {
    path: 'tahsilat-basarili/:tID',
    loadChildren: () =>
      import('./pages/tahsilatlar/t-basarili/t-basarili.module').then(m => m.TBasariliModule)
  },
  {
    path: 'tahsilat-hatali/:mesaj',
    loadChildren: () =>
      import('./pages/tahsilatlar/t-hatali/t-hatali.module').then(m => m.THataliModule)
  },

  {
    path: 'siparis-hatali/:mesaj',
    loadChildren: () =>
    import('./pages/tahsilatlar/t-hatali/t-hatali.module').then(m => m.THataliModule)
  },
  {
    path: 'mesaj-sablon-listesi',
    loadChildren: () =>
    import('./pages/icerik/sablon/mesaj-sablon-liste/mesaj-sablon-liste.module').then(m => m.MesajSablonListeModule)
  },
  {
    path: 'mesaj-sablon-ekle-guncelle/:sablonID',
    loadChildren: () =>
    import('./pages/icerik/sablon/mesaj-sablon-ekle-guncelle/mesaj-sablon-ekle-guncelle.module').then(m => m.MesajSablonEkleGuncelleModule)
  },
  {
    path: 'pazar-yeri',
    loadChildren: () =>
    import('./pages/entegrasyon/pazar-yeri/pazar-yeri.module').then(m => m.PazarYeriModule)
  },
  {
    path: 'musteri-rolleri',
    loadChildren: () =>
    import('./pages/musteri/musteriler/musteri-rol-yonetimi/musteri-rol-yonetimi.module').then(m => m.MusteriRolYonetimiModule)
  },
  {
    path: '**',
    redirectTo: 'home'
    // canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), DxDataGridModule, DxFormModule, DxChartModule, DxPieChartModule, DxResponsiveBoxModule,CommonModule],
  providers: [AuthGuardService],
  exports: [RouterModule],
  declarations: [HomeComponent, ProfileComponent, DisplayDataComponent]
})
export class AppRoutingModule { }
