import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from "guid-typescript";
import { confirm } from 'devextreme/ui/dialog';
declare var moment: any;

@Injectable({ providedIn: 'root' })
export class Ortak {
  static girisYapildi = false;
  static loadingKontrol = false;
  static yetkiler = [];
  static senkTarihi: any;
  static AyarlarJson = {
    FirmaAyarlari: {
      CalismaDovizi: 160,
      Tema: 1,
      OnlineKurBilgileri: 1,
      KurSiteAyari:1
    },
    GenelAyarlar: {
      FiyatAyarlari: { FiyatGoruntulumeListe: 1, FiyatGoruntulemeDetay: 1,SepetKDVDahil:1 },

      UrunAyarlari: {
        FiyatiOlmayanUrunlerListelemeTuru: 1,
        StoguOlmayanUrunlerListelemeTuru: 1,
        ListeGorunumuMarkaAktif: 0,
        SepetListesiMarkaAktif: 0
      },
      UrunKartAyarlari: {
        SepetGoruntulemeListe: 1, SepetGoruntulemeDetay: 1,
        IstekListelesiGoruntulemeListe: 1, IstekListesiGoruntulemeDetay: 1,
        KarsilastirmaListesiGoruntuleListe: 1, KarsilastirmaListesiGoruntuleDetay: 1,
        FavoriGoruntuleListe: 1, FavoriGoruntuleDetay: 1,
        HemenAlGoruntuleListe: 1, HemenAlGoruntuleDetay: 1, UrunKoduGoruntuleDetay: 1
 
      },
      SistemTuru: 1, // 1= B2C + B2B,  2= B2B, 3= Kurumsal
      TakipKodu: '',
      CanliDestekIzlemeKodu: '',
      GoogleMapsAPIKey: '1',
      YandexMapAPIKey: '',
      FBPixelID: '',
      VarsayilanKargoFirmaID: -1,
      SeoTitle: '',
      SeoKeywords: '',
      SeoDesc: '',
      VarsayilanGorunumTuru: 1
    },
    MusteriAyarlari: {
      KayitTuru: 1, // 1= Üyelik Sistemi, 2= Başvuru Sistemi, 3= Kayıt Türü Pasif
      VergiBilgileriZorunlu: false,
      TelefonZorunluMusteriAyarlari:false,
      AdresZorunlu:false,
      YerSecimiZorunlu:false,
    },
    SiparisAyarlari: {
      TamamlamaSecenegi: 1, // 1=Alışverişi tamamla, 2= Teklif Oluştur, 3= TÜmü
      OdemeSecenekleri: {
        KrediKarti: { ID: 1, Aktif: true },
        CokluKrediKarti: { ID: 2, Aktif: false },
        KapidaOdeme: { ID: 3, Aktif: false },
        HavaleEft: { ID: 4, Aktif: false }
      },
      MinimumSepetTutari: 0,
      SepetUrunuAciklamaAktif: false,
      StoktaOlmayanUrunSatilabilsin: false,
      StoktaOlmayanUrunFiyatGoster: true
    },
    BildirimAyarlari: {
      GondericiMailAyarlari: {
        SiparisMail: {
          // tslint:disable-next-line: max-line-length
          Aktif: 1, MailAdresi: '', Sifre: '', SMTP: 1, SSL: false // SMTP 1= Yandex, 2=Google, 3=Outlook    //AKTIF 0=false 1= Herkese 2= Sadece Müşteriler
        },
        SifreBilgilendirme: {
          Aktif: 1, MailAdresi: '', Sifre: '', SMTP: 1, SSL: false // SMTP 1= Yandex, 2=Google, 3=Outlook
        },
        Teklif: {
          Aktif: 1, MailAdresi: '', Sifre: '', SMTP: 1, SSL: false // SMTP 1= Yandex, 2=Google, 3=Outlook
        },
        KampanyaDuyuru: {
          Aktif: 1, MailAdresi: '', Sifre: '', SMTP: 1, SSL: false // SMTP 1= Yandex, 2=Google, 3=Outlook
        },
        SistemMail: {
          Aktif: 1, MailAdresi: '', Sifre: '', SMTP: 1, SSL: false // SMTP 1= Yandex, 2=Google, 3=Outlook
        }
      },
      AliciMailAyarlari: {
        SiparisMail: {
          // tslint:disable-next-line: max-line-length
          Aktif: 1, MailAdresi: '', Sifre: '', SMTP: 1, SSL: false // SMTP 1= Yandex, 2=Google, 3=Outlook    //AKTIF 0=false 1= Herkese 2= Sadece Müşteriler
        },
        SifreBilgilendirme: {
          Aktif: 1, MailAdresi: '', Sifre: '', SMTP: 1, SSL: false // SMTP 1= Yandex, 2=Google, 3=Outlook
        },
        Teklif: {
          Aktif: 1, MailAdresi: '', Sifre: '', SMTP: 1, SSL: false // SMTP 1= Yandex, 2=Google, 3=Outlook
        },
        KampanyaDuyuru: {
          Aktif: 1, MailAdresi: '', Sifre: '', SMTP: 1, SSL: false // SMTP 1= Yandex, 2=Google, 3=Outlook
        },
        SistemMail: {
          Aktif: 1, MailAdresi: '', Sifre: '', SMTP: 1, SSL: false // SMTP 1= Yandex, 2=Google, 3=Outlook
        }
      },
      SmsAyarlari: {
        MusteriBildirimi: false, MusteriMesaj: '', YoneticiBildirimi: false, YoneticiGSM: '', YoneticiMesaj: ''
      }
    },
    EntegrasyonAyarlari: {
      LOGO: {
        ServisAdresi: "",
        KullaniciAdi: "",
        Sifre: "",
        KullaniciID: -1,
        SalesmanRef: 0,
        DepoId: 0
      }
    }

  }






  public storagePersonelGetir = { SLSMAN_USERNAME: '', SLSMAN_PASSWORD: '' };
  hamAdres = '';
  // hamAdres = 'http://servis.m120.zensoft.com.tr';
  constructor(private http: HttpClient) { }
  sifrele(deger) {
    return new Promise((res, err) => {
      if (false) {
        const iv = new Uint8Array([143, 141, 88, 89, 60, 234, 116, 86, 46, 131, 46, 134, 23, 54, 12, 67]);
        const algoEncrypt = { name: 'AES-CBC', iv, tagLength: 128 };
        const sayfa = this;
        window.crypto.subtle
          .importKey('raw', sayfa.strToArrayBuffer('8UHjPgXZzXCGkhxV'), 'AES-CBC', false, ['encrypt', 'decrypt'])
          .then(key => {
            return window.crypto.subtle.encrypt(algoEncrypt, key, sayfa.strToArrayBuffer(deger));
          })
          .then(cipherText => {
            res(btoa(String.fromCharCode.apply(null, new Uint8Array(cipherText))));
          });
      } else {
        res(deger);
      }
    });
  }
  basaSifirEkle(numara: number, haneSayisi: number): string {
    let result = '';
    const eklenecekSifirSayisi = haneSayisi - numara.toString().length;
    if (eklenecekSifirSayisi >= 0) {
      for (let i = 0; i < eklenecekSifirSayisi; i++) {
        result = '0' + result;
      }
    }
    result = result + numara.toString();
    return result;
  }
  strToArrayBuffer(str) {
    const buf = new ArrayBuffer(str.length * 2);
    const bufView = new Uint16Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }
  arrayBufferToString(buf) {
    return String.fromCharCode.apply(null, new Uint16Array(buf));
  }
  createTs() {
    const randomSayi = this.createRandom(9);
    const tarih = new Date();
    const yil = tarih.getFullYear() + randomSayi;
    const ay = tarih.getMonth();
    const gun = tarih.getDate();
    const saat = tarih.getHours();
    const dakika = tarih.getMinutes();
    const sonuc =
      this.basaSifirEkle(saat, 2).substring(0, 1) +
      yil.toString().substring(2) +
      this.basaSifirEkle(saat, 2).substring(1, 2) +
      this.basaSifirEkle(ay, 2) +
      this.basaSifirEkle(dakika, 2).substring(0, 1) +
      this.basaSifirEkle(gun, 2) +
      this.basaSifirEkle(dakika, 2).substring(1, 2) +
      randomSayi.toString();
    return sonuc;
  }
  createRandom(enBuyukSayi: number) {
    return Math.floor(Math.random() * enBuyukSayi + 1);
  }
  public get storageServisBilgisiGetir() {
    return new Promise((res, err) => {
      if (this.hamAdres == '') {
        this.http.get("./assets/files/firmaBilgileri.json").subscribe(a => {
          // const adres=JSON.parse(a as string);
          const adres = a as any;
          this.hamAdres = adres.ServisAdresi;
          res({ HamAdres: this.hamAdres, Adres: this.hamAdres + '/api', TokenAdres: this.hamAdres + '/token' });

        })
      }
      else {
        res({ HamAdres: this.hamAdres, Adres: this.hamAdres + '/api', TokenAdres: this.hamAdres + '/token' });
      }
    })

  }
  public get hamAdresGetir() {
    return this.hamAdres;
  }

  public get senkTarihiGetir() {
    return Ortak.senkTarihi;
  }
  trueFalseValueToNumber(entity) {
    for (let key in entity) {
      if (entity.hasOwnProperty(key) && typeof key !== 'function') {

        // console.log("Tür değeri: ", key, typeof this.urunBilgisi.Urun[key]);

        if (typeof entity[key] == "boolean") {
          // console.log("ele gari: ", key, this.urunBilgisi.Urun[key]);
          entity[key] = entity[key] == true ? 1 : 0;

        }
      } else {

      }

    }
  }

  createGuid() {
    return Guid.create();
  }

  createGuidString() {
    return Guid.create().toString();
  }


  onayIste(baslik: string, mesaj: string) {
    return new Promise((res, err) => {
      let result = confirm("<i>" + mesaj + "</i>", baslik);
      result.then((dialogResult) => {
        res(dialogResult);
      });
    }).catch(x => {
      console.log(x);

    })
  }

  silmeOnayIste() {
    return new Promise((res, err) => {
      let result = confirm("<i>Silme işlemini onaylıyor musunuz?</i>", "Silme Onayı");
      result.then((dialogResult) => {
        res(dialogResult);
      });
    }).catch(x => {
      console.log(x);

    })
  }

  iptalOnayIste() {
    return new Promise((res, err) => {
      let result = confirm("<i>İptal işlemini onaylıyor musunuz?</i>", "İptal Onayı");
      result.then((dialogResult) => {
        res(dialogResult);
      });
    }).catch(x => {
      console.log(x);

    })
  }
  kaydetOnayIste() {
    return new Promise((res, err) => {
      let result = confirm("<i>Kayıt işlemini onaylıyor musunuz?</i>", "Kayıt Onayı");
      result.then((dialogResult) => {
        res(dialogResult);
      });
    }).catch(x => {
      console.log(x);

    })
  }

  devamOnayiIste() {
    return new Promise((res, err) => {
      let result = confirm("<i>İşleme devam etmek istiyor musunuz?</i>", "Onay Penceresi");
      result.then((dialogResult) => {
        res(dialogResult);
      });
    }).catch(x => {
      console.log(x);

    })
  }

  public tarih(tarih: Date, format = "YYYY-MM-DD") {
    return moment(tarih).locale("tr").format(format);
  }

  propertyAta(kaynakNesne, asilNesne) {
    // kaynakNesne = Object.assign({}, asilNesne);
    // kaynakNesne.YETKI_ROOT.MALZEME_YETKI = null;
    // // kaynakNesne.NUMARA_SABLONU.SAI = 10;
    // // kaynakNesne.NUMARA_SABLONU.EFAT = null;
    this.propertyAtaRecursive(kaynakNesne, asilNesne);
    return kaynakNesne;

  }

  propertyAtaRecursive(kaynakNesne, asilNesne) {
    if (kaynakNesne == null) {
      kaynakNesne = asilNesne;
    }
    let nesnePropertyList = Object.keys(asilNesne);
    // console.log("Alt propertiler: ", nesnePropertyList);
    nesnePropertyList.forEach(element => {
      // this.nesnePropertyList = Object.getOwnPropertyNames(asilNesne[element]);
      if (kaynakNesne[element] == null) {
        kaynakNesne[element] = asilNesne[element];
      }
      let altNesne = asilNesne[element];
      // console.log("tippp: ", typeof altNesne);
      // if (typeof altNesne == "object" && altNesne == null) {
      //   console.log("kontrol:",asilNesne,element,altNesne);
      // }
      if (typeof altNesne == "object" && altNesne != null)
        this.propertyAtaRecursive(kaynakNesne[element], asilNesne[element]);
    });
    // let propertyList = Object.getOwnPropertyNames(asilNesne);
  }

  // public get girisBilgisi() {
  //   console.log("Giriş bilgisi: ", this.girisYapildi);

  //   return this.girisYapildi;
  // }




}
