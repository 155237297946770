import { Injectable } from '@angular/core';
import { ServisLogo } from './servisLogo';
import { Servis } from './servis';
import { formatDate } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class LogoSync {

  constructor(private servisLogo: ServisLogo, private servis: Servis) {

  }

  logoMiktarBirimDetaylari() {
    return new Promise((resolve, reject) => {
      this.servis.makeGetRequest("/LogoSenkBirim/LogoBirimleri", true).then(a => {
        const sonuc = a as any;
        console.log("Birimler: ", sonuc);
        resolve(1);

      }).catch(x => {
        console.log("Birimler hata: ", x);
        reject();
      });
    });

  }

  logoCarileri() {
    return new Promise((resolve, reject) => {
      this.servis.makeGetRequest("/LogoSenkMusteri/LogoCariSenk", true).then(a => {
        const sonuc = a as any;
        console.log("Birimler: ", sonuc);
        resolve(1);
      }).catch(x => {
        reject();
      });
    });
  }

  logoMalzemeleri(isimGuncelle) {
    return new Promise((resolve, reject) => {
      var formData = new FormData();
      if (isimGuncelle) {
        formData.append("isimGuncelle", "1");
      }
      else {
        formData.append("isimGuncelle", "0");
      }
      this.servis.makePostRequest("/LogoSenkMalzeme/LogoMalzemeleri", formData, true).then(a => {
        const sonuc = a as any;
        console.log("Malzemeler: ", sonuc);
        resolve(1);

      }).catch(x => {
        reject();
      });
    });
  }

  varyantToUrun() {
    return new Promise((resolve, reject) => {
      this.servis.makeGetRequest("/LogoSenkMalzeme/VaryantOzellikToUrunOzellik", true).then(a => {
        const sonuc = a as any;
        console.log("varyant : ", sonuc);
        resolve(1);

      }).catch(x => {
        reject();
      });
    });
  }


  siparisGonder() {
    return new Promise((resolve, reject) => {
      this.servis.makeGetRequest("/LogoSenkBelge/LogoyaSiparisleriGonder", true).then(a => {
        const sonuc = a as any;
        console.log("siiparisler : ", sonuc);
        resolve(1);

      }).catch(x => {
        reject();
      });
    });
  }

  tahsilatGonder() {
    return new Promise((resolve, reject) => {
      this.servis.makeGetRequest("/LogoSenkBelge/LogoyaTahsilatlariGonder", true).then(a => {
        const sonuc = a as any;
        console.log("Tahsilatlar : ", sonuc);
        resolve(1);

      }).catch(x => {
        reject();
      });
    });
  }

  gonder() {
    return new Promise((res, err) => {
      this.siparisGonder().then(a => {
        this.tahsilatGonder().then(b => {
          res(1);
        }).catch(x => {
          err();
        });
      }).catch(x => {
        err();
      });
    });

  }

  al() {
    return new Promise((res, err) => {
      this.logoCarileri().then(a => {
        this.logoMalzemeleri(false).then(b => {
          this.varyantToUrun().then(c => {
            res(1);
          }).catch(x => {
            err();
          })
        }).catch(x => {
          err();
        })
      }).catch(x => {
        err();
      })
    })
  }

}
