export const navigation = [
  {
    id:0,
    text: 'Anasayfa',
    path: '/home',
    icon: 'home'
  },
  // {
  //   text: 'Examples',
  //   icon: 'folder',
  //   items: [
  //     {
  //       text: 'Profile',
  //       path: '/profile'
  //     },
  //     {
  //       text: 'Display Data',
  //       path: '/display-data'
  //     }
  //   ]
  // },
  // {
  //   text: 'Katalog',
  //   icon: 'folder',
  //   items: [
  //     {
  //       text: 'Ürün',
  //       items: [
  //         { text: 'Ürün Listesi', path: '/urun-listesi' },
  //         { text: 'Ürün Yönetimi', path: '/urun-yonetimi' }
  //       ]
  //     }
  //   ]
  // }

  {
    id:1,
    text: 'Katalog',
    icon: 'fa fa-book',
    items: [
      { text: 'Ürünler', path: '/urun-listesi' },
      { text: 'Kategoriler', path: '/kategori-listesi' },
      { text: 'Kategori Özellikleri', path: '/kategori-ozellikleri' },
      { text: 'Medya', path: '/medya-listesi' },
      { text: 'Birimler', path: '/birim-listesi' },
      // { text: 'Üreticiler', path: '/uretici-listesi' },
      { text: 'Markalar', path: '/marka-listesi' },
    ]
  },
  {
    id:2,
    text: 'Satışlar',
    icon: 'fa fa-credit-card',
    items: [
      { text: 'Aktif siparişler', path: '/siparisler/1' },
      { text: 'Tamamlanan siparişler', path: '/siparisler/2' },
      { text: 'İptal edilenler', path: '/siparisler/3' },
      { text: 'Mevcut alışveriş sepetleri', path: '/mevcut-sepet-listesi' }
      // { text: 'En çok satanlar', path: '/en-cok-satanlar-listesi' },
      // { text: 'Satılmayan ürünler', path: '/satilmayan-urunler-listesi' },
    ]
  },
  {
    id:3,
    text: 'Müşteriler',
    icon: 'fa fa-user',
    items: [
      { text: 'Müşteri listesi', path: '/musteri-listesi' },
      // { text: 'Karaliste', path: '/musteri-listesi/2' },
      { text: 'Tahsilatlar', path: '/tahsilat-listesi' },
      // { text: 'Çevrim içi müşteriler', path: '/musteri-listesi/3' },
      { text: 'Müşteri rolleri', path: '/musteri-rolleri' },
      { text: 'Yetki yönetimi', path: '/yetki-yonetimi' }
    ]
  },
  {
    id:4,
    text: 'Promosyonlar',
    icon: 'fa fa-tags',
    items: [
      { text: 'Kampanyalar', path: '/kampanya-listesi' },
      // { text: 'Kuponlar', path: '/kupon-listesi' },
      // { text: 'Puanlar', path: '/puan-listesi' },
    ]
  },
  {
    id:5,
    text: 'İçerik yönetimi',
    icon: 'fa fa-file-text',
    items: [
      { text: 'Sayfalar', path: '/sayfa-listesi/0' },
      { text: 'Mesaj şablonları', path: '/mesaj-sablon-listesi' },
      { text: 'Topic listesi', path: '/sayfa-listesi/1' },
      { text: 'Dil kaynakları', path: '/dil-kaynaklari' },
    ]
  },
  {
    id:6,
    text: 'Sosyal',
    icon: 'fa fa-share-square-o',
    items: [
      { text: 'Haberler', path: '/haber-duyuru-listesi/1' },
      { text: 'Duyurular', path: '/haber-duyuru-listesi/2' },
      // { text: 'Anket', path: '/anket-listesi' },
      { text: 'Blog', path: '/blog-listesi' },
      // { text: 'Forum', path: '/forum-listesi' },
      { text: 'Sosyal medya', path: '/sosyal-medya' },
    ]
  },
  {
    id:7,
    text: 'Ayarlar',
    icon: 'fa fa-sliders',
    items: [
      { text: 'Genel ayarlar', path: '/ayarlar/1' },
      { text: 'Firma ayarları', path: '/ayarlar/6' },
      { text: 'Müşteri ayarları', path: '/ayarlar/2' },
      { text: 'Sipariş ayarları', path: '/ayarlar/3' },
      { text: 'Bildirim ayarlari', path: '/ayarlar/4' },
      { text: 'Entegrasyon ayarlari', path: '/ayarlar/5' },
      // { text: 'Sepet ayarlari', path: '/ayarlar/6' },
      // { text: 'Medya ayarlari', path: '/ayarlar/7' }
    ]
  },
  {
    id:8,
    text: 'Tanımlamalar',
    icon: 'fa fa-cog',
    items: [
      { text: 'Bölge (Ülke,il, ilçe vs...)', path: '/bolge-listesi' },
      // { text: 'Banka', path: '/banka-listesi' },
      { text: 'Kargo firma', path: '/kargo-firma-liste' },
      { text: 'Diller', path: '/diller' },
      { text: 'Para birimleri', path: '/para-birimleri' },
      { text: 'Ödeme metodları', path: '/odeme-metodlari' },
    ]
  },
  // {
  //   id:9,
  //   text: 'Firma',
  //   icon: 'fa fa-building',
  //   items: [
  //     // { text: 'Alt firmalar', path: '/alt-firmalar' },
  //     // { text: 'Firma yönetimi', path: '/firma-yonetimi' },
  //     // { text: 'Ödeme metodları', path: '/odeme-metodlari' },
  //     // { text: 'E-Posta hesapları', path: '/eposta-hesaplari' }
  //   ]
  // },
  {
    id:10,
    text: 'Entegrasyon',
    icon: 'fa fa-refresh',
    items: [
      // { text: 'Merkez bankası', path: '/merkez-bankasi' },
      { text: 'Excel Aktarım', items: [{ text: "Excel", path: "/excel-senk-anasayfa" }] },
      { text: 'Muhasebe programı', items: [{ text: "Logo", path: "/logo-senk-anasayfa" }] },
      // { text: 'Pazar yeri', items: [{ text: "N11" }, { text: "Gitti gidiyor" }, { text: "Hepsi burada" }] },
      {text:'Pazar yeri', path:'/pazar-yeri'}
    ]
  }
];
