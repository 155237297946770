import { LogoSync } from './shared/services/logoSync';
import { Component, HostBinding } from '@angular/core';
import { AuthService, ScreenService, AppInfoService } from './shared/services';
import { Servis } from './shared/services/servis';
import { Ortak } from './shared/services/ortak';
import { Router } from '@angular/router';
import { SideNavOuterToolbarComponent } from './layouts';
import { navigation } from './app-navigation';
import { Observable, timer } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes).filter(cl => this.screen.sizes[cl]).join(' ');
  }
  onlineKurGetir : any = 0;
  girisYapildi = false;
  timerds = timer(60000, 600000);
  varials;
  static timerAktif = false;
  constructor(private authService: AuthService, private router: Router, private screen: ScreenService, public appInfo: AppInfoService, public ortak: Ortak, public servis: Servis, public logoSync: LogoSync) {
    this.servis.getJSON().subscribe(a => {
      this.ortak.hamAdres = a.ServisAdresi;
      this.servis.makeGetRequest("/MusteriYonetim/KullaniciKontrol").then(a => {
        this.girisYapildi = Ortak.girisYapildi;
        if (Ortak.yetkiler.length <= 0) {
          this.servis.makeGetRequest("/FirmaYonetim/SistemAyarlariGetir").then(b => {

            const ayarSonuc = b as any;
            // if (ayarSonuc.ID > 0) {
            // if (ayarSonuc.FirmaAyarlari == null) {
            //   ayarSonuc.FirmaAyarlari = this.ayarlar.FirmaAyarlari;
            // }

            Ortak.AyarlarJson = this.ortak.propertyAta(ayarSonuc, Ortak.AyarlarJson);
            this.yetkileriGetir();
            // }
          })

        }
      }).catch(x => {
        this.router.navigate(['/giris']);
      });
      // Ortak.girisYapildi = true;
    });
    // alert('app component const');
  }
  async kurGetir(){
    this.timerds = timer(60000, 600000);//
    this.varials = await this.timerds.subscribe(t => {
      if(this.onlineKurGetir != 0){
        this.servis.makeGetRequest("/Tanimlamalar/MerkezBankasindanGetir",false);
      }
    });
  }
  timerDurdur(){
    this.varials.unsubscribe();
  }
  ngOnInit() {
    
    this.servis.makeGetRequest("/FirmaYonetim/SistemAyarlariGetir").then(b => {
      const sonuc = b as any;
      this.onlineKurGetir = sonuc.FirmaAyarlari.OnlineKurBilgileri;
      if(this.onlineKurGetir == 2){
        this.kurGetir();
      }else{
        this.timerDurdur();
      }
    })
  }

  isAutorized() {
    // return this.authService.isLoggedIn;
    return true;
  }
  yetkileriGetir() {
    this.servis.makeGetRequest("/MusteriYonetim/CariYetkiGetir").then(a => {
      const sonuc = a as any;
      Ortak.yetkiler = sonuc;
      this.sayfaAyarla();
      let timerd = timer(2000, 60 * 1000);//
      let timerSubscr = timerd.subscribe(t => {

        this.syncZamanlayici();
      })
    }).catch(x => {

    })
  }

  syncZamanlayici() {
    if (AppComponent.timerAktif == false) {
      AppComponent.timerAktif = true;
      //this.header.syncDurumDegistir(true);
      if (Ortak.yetkiler.length > 0) {
        if (Ortak.yetkiler[0].CariYetkiTurID == 4) {
          if (Ortak.AyarlarJson.EntegrasyonAyarlari.LOGO.ServisAdresi == "" && Ortak.AyarlarJson.EntegrasyonAyarlari.LOGO.KullaniciAdi != "" && Ortak.AyarlarJson.EntegrasyonAyarlari.LOGO.Sifre != "") {
            this.logoSync.gonder().then(a => {
              this.logoSync.al().then(b => {
                AppComponent.timerAktif = false;
                //this.header.syncDurumDegistir(false);
                Ortak.senkTarihi = new Date();
              }).catch(x => {
                AppComponent.timerAktif = false;
                //this.header.syncDurumDegistir(false);

              });
            }).catch(x => {
              AppComponent.timerAktif = false;
              //this.header.syncDurumDegistir(false);

            });
          }
          else {
            //this.header.syncDurumDegistir(false);
            AppComponent.timerAktif = false;
          }
        }
        else {
          //this.header.syncDurumDegistir(false);
          AppComponent.timerAktif = false;
        }
      }
      else {
        //this.header.syncDurumDegistir(false);
        AppComponent.timerAktif = false;
      }
    }

  }

  sayfaAyarla() {
    // debugger;
    let sayfaIdler = Ortak.yetkiler.filter(a => a.Goruntule == 1).map(a => a.CariYetkiTipID);
    SideNavOuterToolbarComponent.menuAyarla(navigation.filter(a => sayfaIdler.includes(a.id) || a.id == 0));
  }

}
