import { Component } from '@angular/core';
import { Servis } from 'src/app/shared/services/servis';

@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent {
  haftalikSiparisler = [];
  kategoriBazliChart:any = [];
  siparisler = [];
  musteriListesiModel = [];
 renderOptions = {
    force: true, // forces redrawing
    animate: false // redraws the UI component without animation
}
  dashboardTopic:any = { AYLIK:{ GENEL_TOPLAM:0, SIPARIS_SAYISI:0 }, HAFTALIK:{ GENEL_TOPLAM:0, SIPARIS_SAYISI:0 }, GUNLUK:{ GENEL_TOPLAM:0, SIPARIS_SAYISI:0 }, TOPLAM:{ GENEL_TOPLAM:0, SIPARIS_SAYISI:0 } };
  constructor(public servis: Servis) { }

  ngOnInit() {
    this.son10Siparis();
    this.MusterileriGetirMethod();
    this.Dashboard();
  }

  Dashboard(){
    this.servis.makeGetRequest("/IcerikYonetim/Dashboard",false).then(p => {
      let sonuc = p as any;
      this.dashboardTopic = sonuc.KAZANCLAR;
      this.kategoriBazliChart = sonuc.KATEGORI_CHART;
      let HAFTALIK_CHART = sonuc.HAFTALIK_CHART;
      HAFTALIK_CHART.forEach(element => {
        let model = { Gun: this.IntToDay(element.GunID), SiparisSayisi:element.SiparisSayisi, SiparisToplami:element.SiparisToplami }
        this.haftalikSiparisler.push(model);
      });
    }).catch(error => {})
  }

  IntToDay(param){
    switch (param) {
      case 0:
        return "Pazar"
      case 1:
        return "Pazartesi"
      case 2:
        return "Salı"
      case 3:
        return "Çarşamba"
      case 4:
        return "Perşembe"
      case 5:
        return "Cuma"
      case 6:
        return "Cumartesi"
    }
  }

  MusterileriGetirMethod() {
    const formData = new FormData();
    formData.append('aranan', '');
    this.servis.makePostRequest('/MusteriYonetim/MusteriAra', formData, true).then(a => {
      const sonuc = a as any;
      this.musteriListesiModel = sonuc;
      this.musteriListesiModel = this.musteriListesiModel.sort(function (a, b) { return b.ID - a.ID }).slice(0, 10);
    }).catch(x => {
      console.log("hata:", x);
    });
  }
  son10Siparis() {
    const formData = new FormData();
    formData.append("siparisDurumIDList", JSON.stringify([1]));
    formData.append("musteriID", "-1");
    formData.append("skip", "0");
    formData.append("take", "10");
    formData.append("order", "2");
    formData.append("aranan", "");
    formData.append("odemeSekli", "-1");
    formData.append("entegrasyonDurum", "-1");
    this.servis.makePostRequest("/BelgeYonetim/Siparisler", formData).then(a => {
      const sonuc = a as any;
      this.siparisler = sonuc;
    }).catch(x => {
      console.log("hata:", x);
    });
  }
}
