import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError, flatMap, switchMap } from 'rxjs/operators';
import { Ortak } from '../services/ortak';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private ortak: Ortak, private http: HttpClient) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    return from(this.ortak.sifrele(this.ortak.createTs())).pipe(
      switchMap((ts: string) => {
        request = request.clone({
          withCredentials: true,
          setHeaders: {
            'x-ts': ts
          }
        });
        return next.handle(request);
      })
    );

    // const token = localStorage.getItem('token');
    // if (token) {
    //   request = request.clone({
    //     setHeaders: {
    //       Authorization: `Bearer ${token}`,
    //       'x-ts': 'res as string'
    //     }
    //   });
    // }
  }
}
