import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Ortak } from './ortak';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class Servis {
  constructor(public ortak: Ortak, public http: HttpClient, private router: Router, private spinner: NgxSpinnerService) { }

  makePostRequest(servisAdresi: string, postData: any, showLoading?: boolean) {
    return new Promise((resolve, reject) => {
      Ortak.girisYapildi = true;
      if (showLoading == null) {
        showLoading = false;
      }
      if (showLoading) {
        this.spinner.show();
      }
      const servisAdresiHam = servisAdresi;
      this.ortak.storageServisBilgisiGetir.then(a => {
        console.log("adres:", a);
        let adres = a as any;
        servisAdresi = adres.Adres + servisAdresi;

        this.http.post(servisAdresi, postData).subscribe(
          data => {
            if (showLoading) {
              this.spinner.hide();
            }
            const anyObj = data as any;
            if (anyObj.Sonuc === 1) {
              resolve(anyObj.SonucJSON);
            } else {
              reject(anyObj);
            }
          },
          error => {
            if (showLoading) {
              this.spinner.hide();
            }
            if (error.status === 401) {
              this.girisCikisSayfasiAyarla();
              // this.tokenAl('', '', 1, '').then(sonuc => {
              //   this.makePostRequest(servisAdresiHam, postData)
              //     .then(c => {
              //       resolve(c);
              //     })
              //     .catch(xyz => {
              //       reject(xyz);
              //     });
              // });
            } else {
              reject(error);
            }
            reject(error);
          }
        );
      }).catch(x => {
        reject(x);
      })

    });
  }
  tokenAl(kullaniciAdi = '', sifre = '', tt = 1, s = '') {
    return new Promise((res, err) => {
      console.group('<------token işlemleri-------->');
      console.log('token alınacak');
      const formData = 'grant_type=password&username=' + kullaniciAdi + '&password=' + sifre + '&tt=' + tt + '&ts=' + s;
      this.ortak.storageServisBilgisiGetir.then(a => {
        console.log("adres:", a);
        let adres = a as any;
        this.http.post(adres.TokenAdres, formData).subscribe(
          a => {
            const sonuc = a as any;
            localStorage.setItem('token', sonuc.access_token);
            res(a);
            console.log('token alma başarılı');
          },
          error2 => {
            console.log('token alma sırasında hata oluştu');
            err(error2);
          },
          () => {
            console.log('token alma işlemleri tamamlandı');
            console.groupEnd();
          }
        );
        // servisAdresi = adres.Adres + servisAdresi;
      }).catch(x => {
        err(x);
      })
      
    });
  }
  makePostRequestWithoutJsonResult(servisAdresi: string, postData: any, showLoading?: boolean) {
    return new Promise((resolve, reject) => {
      Ortak.girisYapildi = true;
      this.ortak.sifrele(this.ortak.createTs()).then((s: string) => {
        if (showLoading == null) {
          showLoading = false;
        }
        if (showLoading) {
          this.spinner.show();
        }
        const servisAdresiHam = servisAdresi;
        this.ortak.storageServisBilgisiGetir.then(a => {
          console.log("adres:", a);
          let adres = a as any;
          servisAdresi = adres.Adres + servisAdresi;
          // servisAdresi = this.ortak.storageServisBilgisiGetir.Adres + servisAdresi;

          this.http.post(servisAdresi, postData).subscribe(
            data => {
              if (showLoading) {
                this.spinner.hide();
              }
              const anyObj = data as any;
              if (anyObj.Sonuc === 1) {
                resolve(anyObj.SonucJSON);
              } else {
                reject(anyObj);
              }
            },
            error => {
              if (showLoading) {
                this.spinner.hide();
              }
              if (error.status === 401) {
                this.girisCikisSayfasiAyarla();
                // this.tokenAl(s).then(sonuc => {
                //   this.makePostRequestWithoutJsonResult(servisAdresiHam, postData)
                //     .then(c => {
                //       resolve(c);
                //     })
                //     .catch(xyz => {
                //       reject(xyz);
                //     });
                // });
              } else {
                reject(error);
              }
              reject(error);
            }
          );
        }).catch(x => {
          reject(x);
        })

      });
    });
  }

  makeGetRequest(servisAdresi: string, showLoading?: boolean) {
    return new Promise((resolve, reject) => {
      Ortak.girisYapildi = true;
      this.ortak.sifrele(this.ortak.createTs()).then((s: string) => {
        if (showLoading == null) {
          showLoading = false;
        }
        if (showLoading) {
          this.spinner.show();
        }
        const servisAdresiHam = servisAdresi;
        this.ortak.storageServisBilgisiGetir.then(a => {
          console.log("adres:", a);
          let adres = a as any;
          servisAdresi = adres.Adres + servisAdresi;
          this.http.get(servisAdresi).subscribe(
            data => {
              if (showLoading) {
                this.spinner.hide();
              }
              const anyObj = data as any;
              if (anyObj.Sonuc === 1) {
                resolve(anyObj.SonucJSON);
              } else {
                reject(anyObj);
              }
            },
            error => {
              if (showLoading) {
                this.spinner.hide();
              }
              if (error.status === 401) {
                this.girisCikisSayfasiAyarla();

                // this.tokenAl(s).then(sonuc => {
                //   this.makeGetRequest(servisAdresiHam)
                //     .then(c => {
                //       resolve(c);
                //     })
                //     .catch(xyz => {
                //       reject(xyz);
                //     });
                // });
              } else {
                reject(error);
              }
              reject(error);
            }
          );
        }).catch(x => {
          reject(x);
        })
        // servisAdresi = this.ortak.storageServisBilgisiGetir.Adres + servisAdresi;

      });
    });
  }

  makeGetRequestWithoutJsonResult(servisAdresi: string, showLoading?: boolean) {
    return new Promise((resolve, reject) => {
      Ortak.girisYapildi = true;
      this.ortak.sifrele(this.ortak.createTs()).then((s: string) => {
        if (showLoading == null) {
          showLoading = false;
        }
        if (showLoading) {
          this.spinner.show();
        }
        const servisAdresiHam = servisAdresi;
        this.ortak.storageServisBilgisiGetir.then(a => {
          console.log("adres:", a);
          let adres = a as any;
          servisAdresi = adres.Adres + servisAdresi;
          // servisAdresi = this.ortak.storageServisBilgisiGetir.Adres + servisAdresi;

          this.http.get(servisAdresi).subscribe(
            data => {
              if (showLoading) {
                this.spinner.hide();
              }
              const anyObj = data as any;
              if (anyObj.Sonuc === 1) {
                resolve(anyObj.SonucJSON);
              } else {
                reject(anyObj);
              }
            },
            error => {
              if (showLoading) {
                this.spinner.hide();
              }
              if (error.status === 401) {
                this.girisCikisSayfasiAyarla();
                // this.tokenAl(s).then(sonuc => {
                //   this.makeGetRequestWithoutJsonResult(servisAdresiHam)
                //     .then(c => {
                //       resolve(c);
                //     })
                //     .catch(xyz => {
                //       reject(xyz);
                //     });
                // });
              } else {
                reject(error);
              }
              reject(error);
            }
          );
        }).catch(x => {
          reject(x);
        })

      });
    });
  }

  girisCikisSayfasiAyarla() {
    console.log("location: ", location);
    Ortak.girisYapildi = false;
    if (location.href.indexOf("giris") < 1) {
      location.href = "/giris";
    }

  }

  public getJSON(): Observable<any> {
    return this.http.get("./assets/files/firmaBilgileri.json");
  }
}
